#login {
  background-color: steelblue;
  background-image: url('../../assets/images/background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  width: 100%;
}

#login .form-container {
  position: relative;
}

#login .avatar {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
}

#login .button {
  margin-top: 2vw;
}

@media (min-width: 768px) {
  #login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #login .logo {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 2vw;
    width: 10%;
  }
}

@media (max-width: 768px) {
  #login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw;
  }

  #login img {
    position: absolute;
    top: 5%;
    width: 50%;
  }
}