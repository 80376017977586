#events {
}

#events .event-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}

#events .event-item {
  box-sizing: border-box;
  height: 25vw;
  padding: 5px;
  width: 20vw;
}

#events .event-item:hover .event-paper {
  box-shadow: 0 10px 10px -10px rgb(255,255,255,0.5);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
#events .event-item:hover .event-container {
  background: rgb(0, 0, 0, 0.5);
}

#events .event-paper {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-sizing: border-box;
  color: whitesmoke !important;
  height: 100%;
  overflow: hidden;
  transition: all ease 0.4s;
}

#events .event-container {
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.30);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 2vw;
  width: 100%;
}

#events  .event-title {
  font-weight: bold !important;
  text-align: right;
}

#events .btn-event {
  margin-top: 1vh;
}

@media (max-width: 768px) { 
  #events .event-item {
    width: 100%;
  }
}