#event-form {
}

#event-form .header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 3vh;
}

.image-field-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 25%;
}

.image-field-container  img {
  width: 100%;
}

.image-field-options {
  align-items: center;
  display: flex;
  justify-content: center;
}

.image-field-input {
  align-items: center;
  display: flex;
}

.image-field-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.image-field-input img {
  width: 20%;
}

.avatars-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.avatar-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 25%;
}
.avatar-item img {
  width: 100%;
}

.add-avatar-bar {
}

.field-margin-top {
  margin-top: 1.3vh;
}

@media (min-width: 769px) {
  .double-field {
    display: flex;
    width: 100%;
  }
  .field {
    width: 100%;
  }
  .double-field .field {
    width: 50%;
  }
  .double-field .image-field-container img {
    height: 10vh;
    width: 10vh;
  }
  .image-field-input {
    max-width: 60%;
  }
}
/*




.image-field {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.image-field img {
  width: 15%;
}

.image-field .container{
  flex-grow: 1;
  margin-right: 1rem;
}

.image-field-buttons {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}
*/

@media (max-width: 768px) { 
  #event-form .header {
    flex-direction: column-reverse;
  }
  .image-field-container {
    padding: 10px;
    width: 100%;
  }
  .image-field-buttons {
    flex-direction: column;
  }
  .banner-item {
    background: rebeccapurple;
  }
}