#modal-root.active {
  align-items: center;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

.flex-row {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

#event-form .header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 3vh;
}


.image-field-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 25%;
}

.image-field-container  img {
  width: 100%;
}

.image-field-options {
  align-items: center;
  display: flex;
  justify-content: center;
}

.image-field-input {
  align-items: center;
  display: flex;
}

.image-field-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.image-field-input img {
  width: 20%;
}

.field-margin-top {
  margin-top: 1.3vh;
}


@media (min-width: 769px) {
  .double-field {
    display: flex;
    width: 100%;
  }
  .field {
    width: 100%;
  }
  .double-field .field {
    width: 50%;
  }
  .double-field .image-field-container img {
    height: 10vh;
    width: 10vh;
  }
}